.faq-section {
    padding: 2rem;
}

.faq-item {
    margin-bottom: 20px;
    border-top: solid #ffffff 1px;
}

.faq-question {
    cursor: pointer;
    border-radius: 15px;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.5rem;
}


.faq-item:first-of-type {
    border-top: none;
}

.faq-question:hover {
    color: #007ACD;
}

.faq-question.active {
    color: #007ACD;
}

.faq-answer {
    font-size: 1.3rem;
    line-height: 2;
    transition: max-height 0.3s cubic-bezier(1, 1, 1, 1);
    max-height: 0;
    overflow: hidden;
}

.faq-answer.is_open {
    max-height: 1000px;
}

.faq-arrow {
    transform-origin: center;
    padding-top: 5px;
}

.faq-arrow.rotateTo {
    animation: rotateto 0.2s forwards;
}

.faq-arrow.rotateFrom {
    animation: rotatefrom 0.2s forwards;
}

@keyframes rotateto {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(-180deg);

    }
}

@keyframes rotatefrom {
    from {
        transform: rotate(-180deg);
    }

    to {
        transform: rotate(0deg);

    }
}