.embla {
    padding: 2rem;
    max-width: 90rem;
    margin: auto;
    --slide-height: 30rem;
    --slide-spacing: 1rem;
    --slide-size: 80%;
}
.embla__viewport {
    overflow: hidden;
}
.embla__container {
    backface-visibility: hidden;
    display: flex;
    touch-action: pan-y;
    margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
    flex: 0 0 var(--slide-size);
    min-width: 0;
    padding-left: var(--slide-spacing);
}

.embla__controls {
    display: grid;
    grid-template-columns: auto 1fr;
    justify-content: space-between;
}
.embla__buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
}
.embla__button {
    -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
    width: 3.6rem;
    height: 3.6rem;
    z-index: 1;
    border-radius: 50%;
    color: var(--text-body);
    display: flex;
    align-items: center;
    justify-content: center;
}
.embla__button:disabled {
    color: var(--detail-high-contrast);
}
.embla__button__svg {
    width: 35%;
    height: 35%;
}

.container-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: center;
}

.image-container {
    width: auto;
    display: flex;
    align-items: center;
}

.responsive-image {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
}
