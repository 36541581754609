.youtube-player {
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    border-radius: 15px;
}

.youtube-player__video {
    position: relative;
    padding-top: 56.25%; /* Задает соотношение сторон 16:9 (высота / ширина) */
    overflow: hidden;
}

.youtube-player__player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px; /* Добавляем закругление в 25 пикселей */
}

/* Медиа-запросы для адаптивности */
@media screen and (max-width: 767px) {
    .youtube-player {
        margin: 0 auto;
    }
}

@media screen and (max-width: 479px) {
    .youtube-player__video {
        padding-top: 75%; /* Изменяем соотношение сторон для мобильных устройств */
    }
}