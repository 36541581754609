.highlights-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.highlight {
    margin: 1rem;
    background-color: #040814;
    border-radius: 10px;
    border: 2px solid white;
    position: relative;
    width: 45%;
    display: flex;
}

.highlight-content-h {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.highlight-title {
    color: #90d3f9;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1;
}

.highlight-description {
    line-height: 1.5;
    font-size: 1.3rem;
}

.highlight-link-container {
    display: flex;
    align-items: center;
    justify-content: start;
}

.content-wrapper {
}

.link-wrapper {
    display: flex;
    vertical-align: center;
}

.highlight-content-h a {
    color: #90d3f9;
    text-decoration: none;
}

.highlight-content-h a:hover {
    color: #90d3f9;
    text-decoration: underline;
}

@media (min-width: 1024px) {
    .link-wrapper a {
        font-size: 1.3rem;
    }
}

@media (max-width: 480px) {
    .highlight {
        width: 100%;
    }
    .highlights-container {
        margin-top: 0;
        margin-bottom: 0;
    }
}