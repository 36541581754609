.animated-number-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
}

.animated-number-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    font-family: 'Syncopate', sans-serif;
}

.animated-number-number {
    font-size: 3.5rem;
    font-weight: bold;
    font-family: 'Syncopate', sans-serif;
    color: #007ACD;
    padding-right: 50px;
}

.animated-number-description {
    font-weight: bold;
    font-size: 2rem;
}

@media (max-width: 768px) {
    .animated-number-content {
        flex-direction: column;
    }
    .animated-number-number {
        padding-right: 0;
    }
}