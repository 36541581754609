.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Устанавливаем высокий z-index, чтобы окно находилось выше других элементов */
}

.popup-content {
    background-color: #000000;
    border-radius: 10px;
    padding: 20px;
    position: relative;
    max-width: 80%;
    max-height: 80vh;
    min-height: 20vh;
    width: 100%;
    overflow: auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    animation: start_popup 0.2s ease-in;

}

.popup-content.close {
    animation: close_popup 0.2s ease-in;
  }

@keyframes start_popup {
    0% {
      transform: scale(0);
    }
  
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes close_popup {
    0% {
      transform: scale(1);
    }
  
    100% {
      transform: scale(0);
    }
  }


.popup-content::-webkit-scrollbar {
    width: 12px;
    /* Ширина скролл-бара */
}

/* Стилизация скролл-бара */
.popup-content::-webkit-scrollbar-track {
    background-color: #1E1E1E;
    /* Цвет трека */
}

.popup-content::-webkit-scrollbar-thumb {
    background-color: #040814;
    /* Цвет ползунка */
    border-radius: 6px;
    /* Радиус закругления ползунка */
}

/* Изменение внешнего вида при наведении на ползунок */
.popup-content::-webkit-scrollbar-thumb:hover {
    background-color: #d08927;
    /* Цвет ползунка при наведении */
}

/* Стилизация скролл-бара в горизонтальном режиме */
.popup-content::-webkit-scrollbar-horizontal {
    height: 12px;
    /* Высота скролл-бара в горизонтальном режиме */
}

.popup-content::-webkit-scrollbar-thumb:horizontal {
    background-color: #040814;
    /* Цвет ползунка в горизонтальном режиме */
}

/* Стилизация скролл-бара в вертикальном режиме */
.popup-content::-webkit-scrollbar-vertical {
    width: 12px;
    /* Ширина скролл-бара в вертикальном режиме */
}

.popup-content::-webkit-scrollbar-thumb:vertical {
    background-color: #040814;
    /* Цвет ползунка в вертикальном режиме */
}

.cross-icon {
    color: white;
    font-size: 24px;
    line-height: 1;
}

.close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
}