.marquee-item img {
    max-height: 80px;
    margin-right: 20px;
}

.marquee-container {
    overflow: hidden;
}

.marquee {
    display: flex;
    animation: marquee 45s linear infinite;
    margin-top: 20px;
    margin-bottom: 20px;
    align-items: center;
    width: max-content;
}

.marquee-item {
    padding: 0 10px;
}

@media (max-width: 478px) {
    .marquee-item img {
        max-height: 50px;
    }

    .marquee {
        animation: marquee 15s linear infinite;
    }
}


@keyframes marquee {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}
